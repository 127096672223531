import "regenerator-runtime/runtime";
import { Button, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { createClient } from '@supabase/supabase-js'
import { useNavigate, useParams } from "react-router-dom";
import { Layout, Image } from 'antd';
import LeftPane from '../../components/LeftPane/LeftPane';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Content } from "antd/es/layout/layout";

const { TabPane } = Tabs;

export const AssetDetail = () => {
  const { id, view } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [assetData, setAssetData] = useState<any>({});
  const [mediaData, setMediaData] = useState<any>([]);
  const [mappingData, setMappingData] = useState<any>({});
  const [cwpTokenData, setCWPTokenData] = useState<any>({});
  const supabaseUrl = "https:\/\/xfhcbezvfnqdjuiomjvq.supabase.co"
  const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhmaGNiZXp2Zm5xZGp1aW9tanZxIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4MjM0NjkzMiwiZXhwIjoxOTk3OTIyOTMyfQ.56M9TuChgr72WW0Iz_gxkZuLYAY6Phe5LerVtZws1VA"
  const supabase = createClient(supabaseUrl, supabaseAnonKey)
  const [permitFrameUrl, setPermitFrameUrl] = useState<any>(null);
  const [cwpFrameUrl, setCWPFrameUrl] = useState<any>(null);
  const [stoFrameUrl, setSTOFrameUrl] = useState<any>(null);
  const [mediaFrameUrl, setMediaFrameUrl] = useState<any>(null);
  const [auditFrameUrl, setAuditFrameUrl] = useState<any>(null);
    
  let navigate = useNavigate();
  
  const getAssetData = async () => {
    setLoading(true);
    const getAssetByID = async () => {
      let {data, error} = await supabase.from('common_asset').select(`
        id,
        common_site (id, name),
        common_asset_type (id, name),
        common_unit (id, name),
        common_loop (id, name),
        parent_asset_id,
        tag
      `).eq('id', id).limit(1).single();
      let permitFrameUrl = "https://product-insights-in.maximl.com/public/dashboard/7e288b1d-3d3f-4afa-8ebe-f4a76544ea7d?asset_name=" + data?.tag
      let cwpFrameUrl = "https://product-insights.azure.maximl.com/public/dashboard/7cb1a44a-a64e-4518-a5a9-b638da9305be?asset_name=" + data?.tag
      // let cwpFrameUrl = "https://product-insights-in.maximl.com/public/dashboard/11e94bbe-fbbe-43c2-9093-1e03f5bffdf3?asset_name=" + data?.tag
      let stoFrameUrl = "https://product-insights-in.maximl.com/public/dashboard/8f4bce00-335b-49a7-9e7a-e70fcd8dc3f2?asset_name=" + data?.tag
      // let mediaFrameUrl = "https://product-insights-in.maximl.com/public/dashboard/2c035f86-3cc8-4845-be4a-d43171f23885?asset_name=" + data?.tag
      
      setPermitFrameUrl(() => permitFrameUrl)
      setCWPFrameUrl(() => cwpFrameUrl)
      setSTOFrameUrl(() => stoFrameUrl)
      setMediaFrameUrl(() => mediaFrameUrl)
      
      setAssetData(()=> data);
    };
    getAssetByID();
    setLoading(false);
  };

  const getMediaData = async () => {
    setLoading(true);
    const setAccessToken = async () => {
      let data = {
        "loginId": "nucleus@maximl.com",
        "password": "Password@123",
        "applicationId": "b53a7520-6b22-43c7-b218-830de3f80b42",
        "noJWT": false
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://login.maximl.net/api/login',
        // headers: { 
        //   'authority': 'login.maximl.net', 
        //   'accept': '*/*', 
        //   'content-type': 'application/json', 
        // },
        data : data
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCWPTokenData(response.data.token)
        const getMappingByID = async () => {
          let {data, error} = await supabase.from('common_mapping').select(`*`).eq('common_id', id).eq('service', "cwp").limit(1).single();
          console.log("mapping", data);
          if(data && data.service_id){
    
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: 'https://dev-nucleus.azure.maximl.com/api2/media?asset='+ data.service_id,
              headers: { 
                'authorization': 'Bearer ' + response.data.token, 
                'x-tenant-id': '6bb66376-59a0-481b-bb18-f36d38e92360',
              }
            };
    
            axios.request(config)
            .then((response) => {
              let imageMedia = [];
              let documentMedia = [];
              response.data.forEach((item) => {
                if (item.media_type === 'image') {
                    imageMedia.push(item);
                } else if (item.media_type === 'file') {
                    documentMedia.push(item);
                }
              });
              setMediaData(imageMedia);
              console.log("media_data", JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
          }
    
        };
        getMappingByID();
      })
      .catch((error) => {
        console.log(error);
      });
    }
    setAccessToken();
    setLoading(false);
  }
  
  useEffect(() => {
    getAssetData();
  }, [id]);

  useEffect(() => {
    getMediaData();
  }, [id]);


  return (
    <Layout className="h-screen">
      <LeftPane />

      <Content
        // className='overflow-y-auto'
        style={{
          // padding: '20px',
          overflow: 'hidden',
          backgroundColor: 'white',
          width: '100%',
        }}

      >
      <Spin spinning={loading} indicator={antIcon}>
        <div className="p-4">
          <div className="flex justify-between items-center pb-2">
            <Button
              type="text"
              onClick={() => {
                navigate("/asset");
              }}
              icon={<ArrowLeftOutlined />}
              className="p-0"
            >
              Back
            </Button>
            <span className="text-[16px] font-semibold" style={{ margin: 'auto', textAlign: 'center', padding: '10px', width: `75%` }}>
              {assetData?.tag}
            </span>
          </div>
          <div>
              { assetData?.common_site?.name } &gt; { assetData?.common_unit?.name } &gt; { assetData?.common_loop?.name } &gt; { assetData?.tag } ({ assetData?.common_asset_type?.name })
          </div>
          <div>
            <Tabs
              onTabClick={(key, event) => {
                window.location.hash = key;
              }}
              defaultActiveKey="JobsPerformed"
              activeKey={
                window.location.hash
                  ? window.location.hash.substr(
                      1,
                      window.location.hash.length - 1
                    )
                  : "JobsPerformed"
              }
            >
              {/* <TabPane tab="Metadata" key="metadata" forceRender={true}>
                <div className="resp-w-h">
                  <div className="flex flex-col">
                    
                    <div className="flex w-full">
                      <div className="flex flex-col w-full mr-4">
                        <span className="font-semibold text-[16px] mb-2">Tag</span>
                        { assetData?.tag }
                      </div>
                      <div className="flex flex-col w-full">
                        <span className="font-semibold text-[16px] mb-2">Asset Type</span>
                        { assetData?.asset_type_name }
                      </div>
                    </div>
                    <br/>
                    
                    <div className="flex w-full">
                      <div className="flex flex-col w-full mr-4">
                        <span className="font-semibold text-[16px] mb-2">Unit</span>
                        { assetData?.unit }
                      </div>
                      <div className="flex flex-col w-full">
                        <span className="font-semibold text-[16px] mb-2">Site</span>
                        { assetData?.site }
                      </div>
                    </div>
                    <br/>

                    <div className="flex w-full">
                      <div className="flex flex-col w-full mr-4">
                        <span className="font-semibold text-[16px] mb-2">Loop</span>
                        { assetData?.loop }
                      </div>
                      <div className="flex flex-col w-full">
                        <span className="font-semibold text-[16px] mb-2"></span>
                      </div>
                    </div>
                    
                  </div>

                </div>
              </TabPane> */}
              <TabPane tab="Jobs Performed" key="JobsPerformed" forceRender={true}>
                <iframe
                  src={cwpFrameUrl}
                  frameBorder="0"
                  width="1200"
                  height="600"
                  allowTransparency
                ></iframe>
              </TabPane>
              <TabPane tab="Shutdowns & Turnarounds" key="ShutdownsTurnarounds" forceRender={true}>
                <iframe
                  src={stoFrameUrl}
                  frameBorder="0"
                  width="1200"
                  height="600"
                  allowTransparency
                ></iframe>
              </TabPane>
              <TabPane tab="Permit" key="Permit" forceRender={true}>
                <iframe
                  src={permitFrameUrl}
                  frameBorder="0"
                  width="1200"
                  height="600"
                  allowTransparency
                ></iframe>
              </TabPane>
              <TabPane tab="Audit Trail" key="AuditTrail" forceRender={true}>
                <iframe
                  src={auditFrameUrl}
                  frameBorder="0"
                  width="1200"
                  height="600"
                  allowTransparency
                ></iframe>
              </TabPane> 
              <TabPane tab="Media" key="Media" forceRender={true}>
              <div>
                <Image.PreviewGroup> 
                  { mediaData.map((image) => (<>
                    <Image width={200} src={image.original_url} />
                  </>))}
                </Image.PreviewGroup> 
              </div>

              </TabPane>      
            </Tabs>
          </div>
        </div>
      </Spin>
      </Content>
    </Layout>
  );
};


export default AssetDetail;
