import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Modal } from "antd";
import React, { useState } from "react";

export const ColumnHiding = ({
  allColumns,
  getToggleHideAllColumnsProps
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      <Button
        style={{ display: "flex", alignItems: "center" }}
        type="default"
        onClick={toggleModal}
      >
        <FilterOutlined />
      </Button>
      <Modal
        title="Show Columns"
        visible={isModalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
      >
        <div>
          <div>
            <Checkbox {...getToggleHideAllColumnsProps()} />
            <label style={{ marginLeft: "10px" }}>Toggle All</label>
          </div>
          <Divider />
          <div className="columnToggleContainer">
            {allColumns.map((column: any) => (
              <div key={column.id} className="columnToggleConfig">
                <Checkbox {...column.getToggleHiddenProps()} />
                <label style={{ marginLeft: "10px" }}>{column.Header}</label>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
