import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Authentication from './pages/Authentication/Authentication';
import { useAppSelector } from './state/hooks';
import RightPane from './components/RightPane/RightPane';
import AssetTable from './pages/Asset/Asset';
import AssetDetail from './pages/AssetDetail/AssetDetail';
import Master from './pages/Master/Master';


const App = () => {
  const loggedIn = useAppSelector((state: any) => state.auth.loggedIn);
  const authChecker = loggedIn ? <AssetTable /> : <Authentication />;
  return (
    <>
      <Routes>
        <Route path="/" element={loggedIn ? <AssetTable /> : <Authentication />} />
        {/* <Route path="/home" element={loggedIn ? <Home /> : <Authentication />} /> */}
        <Route path="/master" element={loggedIn ? <Master /> : <Authentication />} />
        <Route path="asset/:id" element={loggedIn ? <AssetDetail /> : <Authentication />} />
        <Route path="/asset" element={loggedIn ? <AssetTable /> : <Authentication />} />
      </Routes>
    </>
  );
};

export default App;
