import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type authState = {
  user: {
    id: string;
    username: string;
    email: string;
  };
  loggedIn: boolean;
};

const user: any =
  localStorage.getItem('userdata') !== null
    ? JSON.parse(localStorage.getItem('userdata') as string)
    : ({} as any);

const initialState = {
  user:
    localStorage.getItem('userdata') !== null
      ? JSON.parse(localStorage.getItem('userdata') as string)
      : ({} as any),
  loggedIn:
    localStorage.getItem('email') !== null
      ? true
      : false,
} as authState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: authState, action: PayloadAction<any>) => {
      if (localStorage.getItem('email') !== null) {
        state.loggedIn = true;
      }
    },
    logout: (state: authState, action: PayloadAction<any>) => {
      state.loggedIn = false;
      localStorage.removeItem('email');
      localStorage.removeItem('userdata');
    },
    setUser: (state: authState, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
});

export const { login, logout, setUser } = authSlice.actions;
export default authSlice.reducer;
