import { StringColumnFilter } from '../../components/MaximlTable/Filters/StringColumnFilter';
// import { ScopeStatusType } from 'interfaces/scope.interface';
import { Cell } from "react-table";

import { CaretRightOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';




const Columns: Array<any> = [
  {
    id: 'expander', // Make sure it has an ID
    Header: "Asset",
    // Cell: (cell: Cell) => {

    //   // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
    //   // to build the toggle for expanding a row
    //   console.log(cell.row)
    //   // Cell: (cell: any) => (
    //   //   <span className="flex items-center h-full" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
    //   //     <Switch defaultChecked={cell?.row?.original?.is_active} onChange={(e) => { handleSubmit({ ...cell.row.original, is_active: e }) }} ></Switch>
    //   //   </span>

    //   // ),
    //   return (
    //     <div
    //       {...cell.row.getToggleRowExpandedProps({
    //         style: {
    //           // We can even use the row.depth property
    //           // and paddingLeft to indicate the depth
    //           // of the row
    //           paddingLeft: `${cell.row.depth * 1.5}rem`,
    //         },
    //       })}
    //     > 
    //   {/* //<span onClick={(e) => { e.preventDefault(); e.stopPropagation() }}> */}
    //       {cell.row.canExpand ? (
    //             <span>
    //               <Button size='small'
                    
    //               > 
    //                 {cell.row.canExpand ? (cell.row.isExpanded ? <MinusOutlined />:<PlusOutlined />):""}
                    
    //               </Button > {
    //                 //@ts-ignore
    //               cell.row.original?.tag
    //               } 
    //             </span>
    //           ): (<div style={{paddingLeft: `1rem`}}>{
    //             //@ts-ignore
    //             cell.row.original?.tag
    //           }</div>) 
    //         }
    //    {/* // </span> */}
          
    //     </div>
    //   )
    // },
    // maxWidth: 100,
    minWidth: 175,
    Cell: (cell: any) => (
      <span className="flex items-center h-full" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
        <div
          {...cell.row.getToggleRowExpandedProps({
            style: {
              // We can even use the row.depth property
              // and paddingLeft to indicate the depth
              // of the row
              padding: `0.5rem 0rem`,
              paddingLeft: `${cell.row.depth * 1.5}rem`,
            },
          })}
        > 
          {cell.row.canExpand ? (
              <span>
                <Button size='small'
                  onClick={(e) => e.preventDefault()}
                > 
                  {cell.row.canExpand ? (cell.row.isExpanded ? <MinusOutlined />:<PlusOutlined />):""}
                  
                </Button > {
                  //@ts-ignore
                cell.row.original?.tag
                } 
              </span>
            ): (<div style={{paddingLeft: `1rem`}}>{
              //@ts-ignore
              cell.row.original?.tag
            }</div>) 
          }
        </div>
  
      </span>

    ),

  },
  {
    Header: 'ID',
    Footer: 'ID',
    accessor: 'id',
    Filter: StringColumnFilter,
    canGroupBy: false,
    maxWidth: 350,
    minWidth: 250,
    // width: 200
  },
  {
    Header: 'Asset Type',
    Footer: 'Asset Type',
    accessor: 'asset_type.name',
    Filter: StringColumnFilter,
    canGroupBy: false,
    disableSortBy: true,
    maxWidth: 350,
    minWidth: 350,
    // width: 300
  },
  {
    Header: 'Unit',
    Footer: 'Unit',
    accessor: 'unit.name',
    Filter: StringColumnFilter,
    maxWidth: 500,
    minWidth: 350,
    canGroupBy: false,
    // width: 150
  },
  {
    Header: 'Site',
    Footer: 'Site',
    accessor: 'site.name',
    Filter: StringColumnFilter,
    maxWidth: 500,
    minWidth: 300,
    canGroupBy: false,
    // width: 150
  },
{
    Header: 'Parent Asset',
    Footer: 'Parent Asset',
    accessor: 'parent_asset_id',
    Filter: StringColumnFilter,
    // maxWidth: 500,
    // minWidth: 150,
    canGroupBy: false,
    width: 250
  },
  // {
  //   Header: 'View',
  //   Cell: (cell: any)=>{
  //     return (
  //       <Button
  //         href={ "/asset/" + cell.row.original.id}
          
  //         shape="circle" icon={<CaretRightOutlined />} size="small"/>
  //     )
  //   },
  //   // maxWidth: 500,
  //   // minWidth: 150,
  // }
  // {
  //   Header: 'Tag',
  //   Footer: 'Tag',
  //   accessor: 'tag',
  //   Filter: StringColumnFilter,
  //   canGroupBy: false,
  //   maxWidth: 350,
  //   minWidth: 250,
  //   // width: 200
  // },

];

export default Columns;
