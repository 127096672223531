import { Layout } from 'antd';
import React from 'react';
import LeftPane from '../../components/LeftPane/LeftPane';
import RightPane from '../../components/RightPane/RightPane';
import HeaderComponent from '../../components/Header/Header';
import { Content } from 'antd/es/layout/layout';

const Master = () => {
  return (
    <Layout className="h-screen">
        <LeftPane />
        <Content
            // className='overflow-y-auto'
            style={{
            // padding: '20px',
            overflow: 'hidden',
            backgroundColor: 'white',
            width: '100%',
            }}

        >
          <div className="p-2" style={{ width: '100%', height: '100%' }}>
            <HeaderComponent />

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <iframe
                src="https://maximl3.retool.com/apps/Common%20Assets%20Staging"
                style={{ width: '100%', height: '90%', position: 'absolute', top: 0, left: 0 }}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
             
        </Content>
      {/* <RightPane /> */}
    </Layout>
  );
};

export default Master;
