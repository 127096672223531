import { Input } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";

export const StringColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;
  return (
    <span>
      <Input
        className="font-normal"
        placeholder="Search"
        suffix={<SearchOutlined />}
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};
