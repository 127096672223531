import "regenerator-runtime/runtime";
import "./styles.css";
import "leaflet/dist/leaflet.css";

import { RightSquareOutlined, SearchOutlined, ApartmentOutlined, CarryOutOutlined, DownOutlined } from '@ant-design/icons';
import MaximlTable from "../../components/MaximlTable/Table";
import { useEffect, useState } from "react";
import Columns from "./Columns";
import { createClient } from '@supabase/supabase-js'
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Layout, Tabs, Tree } from 'antd';
import LeftPane from '../../components/LeftPane/LeftPane';
import Menu, { MenuProps } from "antd/es/menu/menu";
import HeaderComponent from "../../components/Header/Header";
import { Content } from "antd/es/layout/layout";
import TabPane from "antd/es/tabs/TabPane";
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  Tooltip,
  TileLayer,
  useMapEvents,
  Polygon,
} from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Icon, divIcon, point } from "leaflet";
import ToolTipCard from "./components/ToolTipCard";


function LocationMarker() {
  const [position, setPosition] = useState<LatLngTuple>([48.86, 2.3522])
  const map = useMapEvents({
    click(e) {
      console.log(e);
      // setPosition([e.layerPoint.x, e.layerPoint.y]);
      setPosition([e.latlng.lat, e.latlng.lng]);
    },
    // locationfound(e) {
    //   map.flyTo(e.latlng, map.getZoom())
    // },
  })

  return (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  )
}

export const assetsTransform = (assets: any) => {
  let assetList = assets;
  let map: { [asset_id: string]: any } = {}, node,
    roots = [],
    i;
  
  if(assetList){
    for (let i = 0; i < assetList.length; i += 1) {
      map[assetList[i].id] = i; // initialize the map
      assetList[i]['subRows'] = []; // initialize the subRows
    }

    for (i = 0; i < assetList.length; i += 1) {
      node = assetList[i];
      if (node.parent_asset_id) {
        // if you have dangling branches check that map[node.parentId] exists
        assetList[map[node.parent_asset_id]]?.subRows!.push({
          id: node.id,
          subRows: node.subRows,
          tag: node.tag,
          asset_type: node.common_asset_type,
          unit: node.common_unit,
          site: node.common_site,
          loop: node.common_loop,
          // icon: <CarryOutOutlined /> ,
        });
      } else {
        roots.push({
          id: node.id,
          subRows: node.subRows,
          tag: node.tag,
          asset_type: node.common_asset_type,
          unit: node.common_unit,
          site: node.common_site,
          loop: node.common_loop,
          // asset_type_name: node.asset_type_name,
          // unit: node.unit,
          // site: node.site,
          // icon: <ApartmentOutlined />,
        });
      }
    }

  }
  return roots;
};

export const assetsHierarchyTransform = (assets: any) => {
  let assetList = assets;
  let map: { [asset_id: string]: any } = {}, node,
    roots = [];
  
  if(assetList.length > 0){
    for (let i = 0; i < assetList.length; i += 1) {
      map[assetList[i].id] = i; // initialize the map
      assetList[i]['children'] = []; // initialize the children
      assetList[i]['key'] = assetList[i].id; // initialize the key
      assetList[i]['title'] = assetList[i].common_asset_type ? assetList[i].tag + "  (" + assetList[i].common_asset_type.name + ")" : assetList[i].tag; // initialize the children
    }

    for (let i = 0; i < assetList.length; i += 1) {
      node = assetList[i];
      if (node.parent_asset_id) {
        // if you have dangling branches check that map[node.parentId] 
        assetList[map[node.parent_asset_id]]?.children.push(node);
      } else {
        roots.push(node);
      }
    }
  }
  return roots;
};

export const hierarchyTransform = (parent: any, child: any, identifier: string) => {
  let dataList = parent;
  let childList = child;
  let map: { [data_id: string]: any } = {}, node,
    roots = [],
    i;
  if(dataList){
    for (let i = 0; i < dataList.length; i += 1) {
      map[dataList[i].id] = i; // initialize the map
      dataList[i]['children'] = []; // initialize the children
      dataList[i]['key'] = dataList[i].id; // initialize the key
      if (dataList[i]?.identifier == "asset"){
        dataList[i]['title'] = dataList[i].tag; // initialize the title
      }
      else{
        dataList[i]['title'] = dataList[i].name; // initialize the title
      }
    }
    // for (let i = 0; i < childList.length; i += 1) {
    //   // childList[i]['children'] = []; // initialize the children
    //   childList[i]['key'] = childList[i].id; // initialize the key
    //   childList[i]['title'] = childList[i].name; // initialize the title
    // }
    if (childList){
      for (let i = 0; i < childList.length; i += 1) {
        if(childList[i] && childList[i][identifier]){
          dataList[map[childList[i][identifier]]]?.children.push(childList[i]);
        }
        else{
          roots.push(childList[i])
        }
      }
    }
    for (let i = 0; i < dataList.length; i += 1){
      roots.push(dataList[i])
    } 
  }
  return roots;
}


const customIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  // iconUrl: require("./icons/placeholder.png"),
  iconSize: [38, 38] // size of the icon
});

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
  return divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true)
  });
};


const AssetTable = () => {
  const supabaseUrl = "https:\/\/xfhcbezvfnqdjuiomjvq.supabase.co"
  const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhmaGNiZXp2Zm5xZGp1aW9tanZxIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4MjM0NjkzMiwiZXhwIjoxOTk3OTIyOTMyfQ.56M9TuChgr72WW0Iz_gxkZuLYAY6Phe5LerVtZws1VA"

  const supabase = createClient(supabaseUrl, supabaseAnonKey)
  const defaultFilters = [];

  const [assetData, setAssetData] = useState<any>([]);
  const [assetTableData, setAssetTableData] = useState<any>([]);
  const [assetHierarchyData, setAssetHierarchyData] = useState<any>([]);
  const [siteData, setSiteData] = useState<any>([]);
  const [unitData, setUnitData] = useState<any>([]);
  const [loopData, setLoopData] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [width, height] = useWindowSize();
  let navigate = useNavigate();
  const [pageX, setPageX] = useState<number>(0);
  const [pageY, setPageY] = useState<number>(0);
  const [row, setRow] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [assetMarkerData, setAssetMarkerData] = useState<any>([]);
  const [siteMarkerData, setSiteMarkerData] = useState<any>([]);
  const [unitMarkerData, setUnitMarkerData] = useState<any>([]);
  const [loopMarkerData, setLoopMarkerData] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  // https://tanstack.com/table/v8/docs/examples/react/expanding
  useEffect(() => {
    const getAsset = async () => {
      const {data, error} = await supabase.from('common_asset').select(`
        id,
        common_site (id, name, coordinates),
        common_asset_type (id, name),
        common_unit (id, name, coordinates),
        common_loop (id, name, coordinates),
        parent_asset_id,
        tag,
        latitude,
        longitude
      `).order('last_updated_at', { ascending: false });
      setAssetData(data);
      setAssetTableData(()=> assetsTransform(data));
    };
    setLoading(true);
    getAsset();
    setLoading(false);
  }, []);

  useEffect(() => {
    const getAsset = async () => {
      let assetMarkers = []
      const {data, error} = await supabase.from('common_asset').select(`
        id, tag, parent_asset_id,
        common_asset_type (id, name),
        common_site (id, name),
        common_unit (id, name),
        common_loop (id, name),
        site_id,
        unit_id,
        loop_id,
        name,
        serial_number,
        level,
        latitude,
        longitude
      `).order('last_updated_at', { ascending: false });
      data.map((v) => {
        v["identifier"] = "asset"
        v["selectable"] = true
        if(v.latitude && v.longitude){
          assetMarkers.push({lat: v.latitude, lng: v.longitude, popUp: v.tag, data: v})
        }
      })
      setAssetMarkerData(assetMarkers);
      setAssetHierarchyData(data);
    };
    const getLoop = async () => {
      let loopMarkers = []
      const {data, error} = await supabase.from('common_loop').select('*').order('last_updated_at', { ascending: false });
      data.map((v) => {
        v.identifier = "loop"
        v.selectable = false
        if(v.coordinates?.length>0){
          loopMarkers.push(v.coordinates)
        }
      })
      setLoopMarkerData(loopMarkers);
      setLoopData(data);
    };
    const getUnit = async () => {
      let unitMarkers = []
      const {data, error} = await supabase.from('common_unit').select('*').order('last_updated_at', { ascending: false });
      data.map((v) => {
        v.identifier = "unit"
        v.selectable = false
        if(v.coordinates?.length>0){
          unitMarkers.push({coordinates: v.coordinates, popUpData: v.name})
        }
      })
      setUnitMarkerData(unitMarkers);
      setUnitData(data);
    };
    const getSite = async () => {
      let siteMarkers = []
      const {data, error} = await supabase.from('common_site').select('*').order('last_updated_at', { ascending: false });
      data.map((v) => {
        v.identifier = "site"
        v.selectable = false
        if(v.coordinates.length > 0){
          siteMarkers.push({coordinates: v.coordinates, popUpData: v.name})
        }
      })
      setSiteMarkerData(siteMarkers);
      setSiteData(data);
    };
    getSite();
    getUnit();
    getLoop();
    getAsset();
  }, []);


  const updateData = (updatedData: any) => {
    const getAsset = async () => {
      const {data, error} = await supabase.from('common_asset').select(`
        id,
        common_site (id, name),
        common_asset_type (id, name),
        common_unit (id, name),
        common_loop (id, name),
        parent_asset_id,
        tag,
        latitude,
        longitude,
      `).order('last_updated_at', { ascending: false });
      setAssetData(()=> assetsTransform(data));
    };
    setLoading(true);
    getAsset();
    setLoading(false);
  };


  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      setVisible(false);
      console.log("asset", row);
    }
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const showDrawer = (row: any) => {
    console.log("asset", row);
    if (!row.isGrouped) {
      setRow(row.original);
      navigate(`/asset/${row.original.id}`, { state: row.original as any });
    }
  };
  const showMenu = (row: any, x: number, y: number) => {
    setSelectedRow(row);
    setPageX(x);
    setPageY(y);
    setVisible(true);
  };

  const onSelectHierarchy = (selectedKeys: React.Key[], info: any) => {
    setRow(selectedRow);
    navigate(`/asset/${info.node.id}`, { state: info.node as any });
  };


  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          onClick: () => {
            console.log(selectedRow)
            setRow(selectedRow);
            navigate(`/asset/${selectedRow.id}`, { state: selectedRow as any });
          },
          label: "View",
          key: "1"
        }
      ]}
    />
  );

  return (
    <Layout className="h-screen bg-white">
      <LeftPane />

      <Content
        // className='overflow-y-auto'
        style={{
          // padding: '20px',
          overflow: 'hidden',
          backgroundColor: 'white',
          width: '100%',
        }}

      >

        <div className="p-2 " >
          <HeaderComponent />
          <h1 style={{ margin: '-40px 0px 10px 10px' }}>Asset Central</h1>
          <div style={{height:height-40, margin: '0px 0px 0px 10px'}} className="overflow-auto">
            <Tabs
              onTabClick={(key, event) => {
                window.location.hash = key;
              }}
              defaultActiveKey="HierarchyView"
              activeKey={
                window.location.hash
                  ? window.location.hash.substr(
                      1,
                      window.location.hash.length - 1
                    )
                  : "HierarchyView"
              }
            >
              <TabPane tab="Hierarchy" key="HierarchyView" forceRender={true}>
                <div className="h-full overflow-y-auto">
                  <Tree
                    // showLine
                    switcherIcon={<DownOutlined />}
                    defaultExpandedKeys={['0-0-0']}
                    treeData={hierarchyTransform(
                      siteData, 
                      hierarchyTransform(
                        unitData,
                        hierarchyTransform(
                          loopData,
                          assetsHierarchyTransform(assetHierarchyData), 
                          "loop_id"
                        ), 
                        "unit_id"
                      ), 
                      "site_id"
                    )}
                    onSelect={onSelectHierarchy}
                    showIcon={true}
                    // selectedKeys={[assetDetails.assetId]}
                  />
                </div>
              </TabPane>
              <TabPane tab="Table" key="TableView" forceRender={true}>
                <div style={{ margin: 'auto', padding: '10px', width: `100%` }}>
                  <MaximlTable
                    data={assetTableData}
                    updateData={updateData}
                    columns={Columns}
                    loading={loading}
                    hiddenColumns={['id', 'parent_asset_id']}
                    sorting
                    pagination
                    columnHiding
                    columnResizing
                    columnFiltering
                    globalFiltering
                    columnGrouping
                    onRightClick={showMenu}
                    onLeftClick={(row) => {
                      if (!row.isGrouped) {
                        setRow(row.original);
                        navigate(`/asset/${row.original.id}`, { state: row.original as any });
                      }
                    }}
                    // onLeftClick={showDrawer}
                    // customButton={}
                    // defaultFilters={defaultFilters}
                    placeholderText={"Search for assets"}
                    height={height - 220}
                    defaultPageSize={25}
                  />
                </div>
              </TabPane>
              <TabPane tab="Map" key="MapView" forceRender={false}>
                <div id="mapdiv" style={{ padding: '10px', width: `80%` }}>
                  <MapContainer center={[24.250147, 78.154784]} zoom={13}>
                  {/* <MapContainer center={[12.97913685, 77.643205513]} zoom={13}> */}
                    {/* OPEN STREEN MAPS TILES */}
                    <TileLayer
                      attribution="Google Maps"
                      // url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" // regular
                      url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" // satellite
                      // url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" // terrain
                      maxZoom={21}
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    />
                    {/* <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    /> */}

                    <MarkerClusterGroup
                      chunkedLoading
                      iconCreateFunction={createClusterCustomIcon}
                    >
                      {/* Mapping through the markers */}
                      {assetMarkerData.map((marker: any) => (
                        <Marker 
                          position={[marker.lat, marker.lng]} 
                          icon={customIcon}
                          eventHandlers={{
                            click: (e) => {
                              console.log('marker clicked', e)
                            }
                          }}
                        >
                          <Popup>
                            <ToolTipCard asset={marker.data} />
                            {/* {marker.popUp}
                            <Button style={{ margin: '0px 0px 0px 10px'}} shape="circle" icon={<RightSquareOutlined />} onClick={() => {
                              navigate(`/asset/${marker.data.id}`, { state: marker.data as any });
                            }}/> */}
                          </Popup>
                        </Marker>
                      ))}

                      {siteMarkerData.map((marker: any) => (
                        <Polygon pathOptions={{ color: 'purple' }} positions={marker.coordinates}>
                          <Popup>
                            {marker.popUpData}
                          </Popup>
                        </Polygon>
                      ))}

                      {unitMarkerData.map((marker: any) => (
                        <Polygon pathOptions={{ color: 'yellow' }} positions={marker.coordinates}>
                          <Popup>
                            {marker.popUpData}
                          </Popup>
                        </Polygon>
                      ))}
                      <LocationMarker/>
                    </MarkerClusterGroup>

                  </MapContainer>
                </div>
              </TabPane>
              
            </Tabs>
          </div>
        </div>
      </Content>

    </Layout>
  );
};

export default AssetTable;


