import { Button, Typography } from 'antd';
import { useNavigate } from "react-router-dom";


const { Text } = Typography;

export const AssetCard = ({ asset }) => {
  let navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <div className="w-full">
          <div className="flex flex-row justify-between">
            <Text strong>Asset</Text>
            <Text>{asset.tag}</Text>
          </div>
          <div className="flex flex-row justify-between">
            <Text strong>Asset Type</Text>
            <Text>{ asset?.common_asset_type.name.length > 15 ? asset?.common_asset_type.name.slice(0, 15)+ "..." : asset?.common_asset_type.name }</Text>
          </div>
          <div className="flex flex-row justify-between">
            <Text strong>Unit</Text>
            <Text>{asset?.common_unit.name}</Text>
          </div>
          <div className="flex flex-row justify-between">
            <Text strong>Site</Text>
            <Text>{asset?.common_site.name}</Text>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-2 mb-1">
        <div className="flex flex-row items-center">
        </div>
        <div
          onClick={() =>{
            navigate(`/asset/${asset.id}`, { state: asset.data as any });
          }}
        >
          <Button type="primary">View Details</Button>
        </div>
      </div>
    </div>
  );
};
